import { IStateDiagnostic, DiagnosticEnum } from '@/store/modules/diagnostic'

export default {
  compositeDiagnostic: (state: IStateDiagnostic) => (serial: string) => {
    let result = null
    const index = state.strength.findIndex(composite => composite.serial === serial)
    if (!state.strength[index]) return

    const { value, rangeAcceptableMax, rangeAcceptableMin } = state.strength[index].strength
    if (rangeAcceptableMax < value) {
      result = DiagnosticEnum.TooClose
    } else if (value < rangeAcceptableMin) {
      result = DiagnosticEnum.TooFar
    }

    return result
  }
}
