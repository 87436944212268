import { IStateHistory, IMotionEventRange, IRecentHistoryEventGraph } from '@/store/modules/history'
import { PLOTBANDS_COLOR } from '@/utils/constant'

export default {
  humanMotionEvents: ({ humanMotionHistory }: IStateHistory) => {
    const humanMotionEvents: IRecentHistoryEventGraph[] = []

    humanMotionHistory.forEach(event => {
      const humanMotion: IRecentHistoryEventGraph = {
        x: event.startTsMs,
        y: 1,
        z: event.stopTsMs
      }
      humanMotionEvents.push(humanMotion)
    })

    return humanMotionEvents
  },

  humanMotionRanges: ({ humanMotionHistory }: IStateHistory) => {
    const humanMotionRanges: IMotionEventRange[] = []

    humanMotionHistory.forEach(event => {
      if (event === undefined) return

      const humanMotionRange = {
        from: event.startTsMs,
        to: event.stopTsMs,
        color: PLOTBANDS_COLOR
      }
      humanMotionRanges.push(humanMotionRange)
    })

    return humanMotionRanges
  },

  suspectedMotionEvents: ({ suspectedMotionHistory }: IStateHistory) => {
    const suspectedMotionEvents: IRecentHistoryEventGraph[] = []

    suspectedMotionHistory.forEach(event => {
      const suspectedMotion: IRecentHistoryEventGraph = {
        x: event.startTsMs,
        y: 1,
        z: event.stopTsMs
      }
      suspectedMotionEvents.push(suspectedMotion)
    })

    return suspectedMotionEvents
  },

  suspectedMotionRanges: ({ suspectedMotionHistory }: IStateHistory) => {
    const suspectedMotionRanges: IMotionEventRange[] = []

    suspectedMotionHistory.forEach(event => {
      if (event === undefined) return

      const suspectedMotionRange = {
        from: event.startTsMs,
        to: event.stopTsMs,
        color: PLOTBANDS_COLOR
      }
      suspectedMotionRanges.push(suspectedMotionRange)
    })

    return suspectedMotionRanges
  }
}
