import { getOpStatusInfo, OpStatusCodeEnum } from '@/utils/thingStatus'
import { IStateComposite, ICompositeThing } from '@/store/modules/composite'
import { APIThing } from '@/type/things/thing'
import { AgentModeEnum } from '@/store/modules/composite/index'
import { loadingStatusInfo } from '@/utils/thingStatus'

export default {
  setComposite(state: IStateComposite, compositeData: APIThing[]) {
    state.compositeThings = []

    compositeData.forEach(compositeThing => {
      const { alias, serial, desired, reported } = compositeThing
      const { agentWifiOpMode, connected } = reported
      const clientDesired = desired && desired.wifiClientMac ? desired.wifiClientMac : null
      const clientReported = reported.wifiClientMac
      const sensitivityReported = reported.motionAlgoSensitivity
      const sensitivityDesired = desired.motionAlgoSensitivity

      let opStatus = loadingStatusInfo
      if (reported.agentWifiOpMode == AgentModeEnum.Ap && reported.wifiClientMac !== desired.wifiClientMac) {
        opStatus = getOpStatusInfo(OpStatusCodeEnum.INFO_INITIALIZING)
      } else {
        opStatus = getOpStatusInfo(reported.opStatus)
      }

      const compositeThingLean: ICompositeThing = {
        alias,
        agentWifiOpMode,
        clientDesired,
        clientReported,
        connected,
        opStatus,
        sensitivityDesired,
        sensitivityReported,
        serial
      }

      state.compositeThings = [...state.compositeThings, compositeThingLean]
    })
  },

  resetComposite(state: IStateComposite) {
    state.compositeThings = []
  }
}
