


















import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import Nav from '@/components/Drawer/Nav.vue'
import Notifs from '@/components/Drawer/Notif.vue'
import User from '@/components/Drawer/User.vue'
import Header from '@/components/Layout/Header.vue'
import { IStateDrawer } from '@/store/modules/drawer'
import { SLIDE_TRANSITION } from '@/utils/constant'

export default Vue.extend({
  components: {
    Nav,
    Notifs,
    User
  },
  data() {
    return {
      transitionName: SLIDE_TRANSITION
    }
  },
  computed: mapState<IStateDrawer>('drawer', {
    drawer: (state: IStateDrawer) => state.drawer,
    drawers: (state: IStateDrawer) => state.drawers
  }),
  methods: {
    closeDrawers() {
      const { drawer, drawers } = this.$store.state.drawer
      if (drawer === drawers.None) return

      this.$store.commit('drawer/closeDrawers')
    }
  }
})
