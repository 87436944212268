import actions from '@/store/modules/client/actions'
import getters from '@/store/modules/client/getters'
import mutations from '@/store/modules/client/mutations'

export const state: IStateClient = {
  clients: []
}

export const client = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export interface IStateClient {
  clients: ICompositeClient[]
}

export interface ICompositeClient {
  serial: string
  connectedClients: string[]
  clientOptions: IClientOption[]
}

export type IClientOption = {
  client: string
  value: string
}
