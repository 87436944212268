var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "card" }, [
    _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("p", { staticClass: "content" }, [_vm._v(_vm._s(_vm.content))]),
    _c(
      "p",
      { staticClass: "date" },
      [
        _c("font-awesome-icon", { attrs: { icon: ["fas", "clock"] } }),
        _vm._v(
          " " +
            _vm._s(_vm.$t("Notif.Drawer.CreatedAt")) +
            " " +
            _vm._s(_vm.$d(new Date(), "long", this.$i18n.locale)) +
            " "
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }