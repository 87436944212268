var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout" },
    [
      _c(
        "div",
        {
          staticClass: "layout__main",
          class: { "layout__main--blur": _vm.drawer !== _vm.drawers.None },
          on: { click: _vm.closeDrawers }
        },
        [
          _c(
            "transition",
            { attrs: { name: _vm.transitionName, mode: "out-in", appear: "" } },
            [_c("router-view")],
            1
          ),
          _c("img", {
            staticClass: "aerialito",
            attrs: { src: require("@/assets/aerialito-white.png") }
          })
        ],
        1
      ),
      _c("Nav", { staticClass: "drawer drawer__nav drawer--left" }),
      _c("Notifs", { staticClass: "drawer drawer--right" }),
      _c("User", { staticClass: "drawer drawer--right" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }