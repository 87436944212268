import { AxiosResponse } from 'axios'

import { APIOrgs } from '@/type/orgs'
import fetchApi from '@/utils/axios'
import { EnvTarget } from '@/utils/api'
import { STG_ORG_ID } from '@/utils/constant'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getOrgs({ commit }: any) {
    const envTarget: EnvTarget = process.env.NODE_ENV

    try {
      const response: AxiosResponse<APIOrgs> = await fetchApi.get('/orgs')

      let orgs = response.data.organizations
      if (envTarget === EnvTarget.Staging) orgs = orgs.filter(org => org.id === STG_ORG_ID)

      commit('org/setOrgs', orgs, { root: true })
      if (orgs.length > 0) commit('org/setOrg', orgs[0], { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('org/setOrgs', [], { root: true })
    }
  }
}
