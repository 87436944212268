import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// Route level are code-splitting with webpackChunkName.
// This generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const routes = [
  {
    path: '/',
    name: 'live',
    component: () => import(/* webpackChunkName: "live" */ '@/views/Live.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/history/suspected',
    name: 'historySuspected',
    component: () => import(/* webpackChunkName: "historySuspected" */ '@/views/History/Suspected.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/history/human',
    name: 'historyHuman',
    component: () => import(/* webpackChunkName: "historyHuman" */ '@/views/History/Human.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/setup',
    name: 'setup',
    component: () => import(/* webpackChunkName: "setup" */ '@/views/Setup.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/settings/:serial',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    // Wild card will match everything else falling back to a 404 error view.
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: { transitionName: 'slide' }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
