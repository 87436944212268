import { IStateClient } from '@/store/modules/client'
import { APIClientInConnectedClients } from '@/type/things/connectedClients'

export const clientOptionNone = { client: 'none', value: '' }

export default {
  setCompositeConnectedClients(state: IStateClient, { response, serial }: any) {
    const connectedClients = response.clients.map((client: APIClientInConnectedClients) => client.mac)
    const clientOptions = [clientOptionNone]

    response.clients.forEach((client: APIClientInConnectedClients) => {
      clientOptions.push({ client: client.mac, value: client.mac })
    })

    const compositeConnectedClients = {
      serial,
      connectedClients,
      clientOptions
    }

    state.clients = [...state.clients, compositeConnectedClients]
  },

  resetCompositeConnectedClients(state: IStateClient) {
    state.clients = []
  }
}
