/* Aerial org id dedicated for test usage in staging env */
export const STG_ORG_ID = 'aa8af3f2'

/** timezone offset for graphs interpretation of API data */
export const TIMEZONE_OFFSET = new Date().getTimezoneOffset()

/** mobile query in px */
export const MOBILE_WIDTH = 600

// Motion status
export const REFRESH_MOTION_MS = 2000
export const SOFT_MAX_RAW_LEVEL = 400

// Client reported vs desired
export const REFRESH_CLIENT_MATCHES_MS = 2500

// Thing state
export const REFRESH_THING_STATE_MS = 2500

// History Charts
export const CHART_HEIGHT = 200
export const PLOTBANDS_COLOR = '#7CB5EC'
export const TURBO_TRESHOLD = 10000

// Animation transition
export const SLIDE_TRANSITION = 'slide'
export const FADE_TRANSITION = 'fade'
