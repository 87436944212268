














import Vue from 'vue'

interface ILang {
  displayName: string
  value: string
}
export default Vue.extend({
  data() {
    return {
      langs: [
        { displayName: 'English', value: 'en' },
        { displayName: 'French', value: 'fr' }
      ]
    }
  },
  methods: {
    setLang(lang: ILang) {
      this.$i18n.locale = lang.value
    }
  }
})
