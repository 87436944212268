






















import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import Notification from '@/components/Notif/Drawer.vue'
import { IStateDrawer } from '@/store/modules/drawer'
import { IStateNotification } from '@/store/modules/notification'

export default Vue.extend({
  components: {
    Notification
  },
  computed: {
    ...mapState<IStateDrawer>('drawer', {
      drawer: (state: IStateDrawer) => state.drawer,
      drawers: (state: IStateDrawer) => state.drawers
    }),
    ...mapState<IStateNotification>('notification', {
      notifications: (state: IStateNotification) => state.notifications,
      hasNotification: (state: IStateNotification) => state.notifications.length > 0
    })
  },
  methods: {
    ...mapMutations('drawer', ['closeDrawers']),
    ...mapMutations('notification', ['resetNotification'])
  }
})
