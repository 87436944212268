import { AxiosResponse } from 'axios'

import { ICompositeThing } from '@/store/modules/composite/index'
import { APIMotionStatus } from '@/type/things/motion/status'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getMotionStatus({ commit, rootState }: any) {
    const { serial } = rootState.thing
    const { compositeThings } = rootState.composite

    let connectedThing = []
    if (compositeThings && compositeThings.length) {
      connectedThing = compositeThings.filter((thing: ICompositeThing) => thing.connected)
    }

    if (connectedThing.length > 0 && serial) {
      try {
        const response: AxiosResponse<APIMotionStatus> = await fetchApi.get(
          `/thing/${serial}/motion/compositeStatus`
        )
        commit('activity/setMotionStatus', response.data, { root: true })
      } catch (error) {
        sentryFetchError(error.response)
        commit('notification/addNotification', error.response, { root: true })
      }
    }
  }
}
