














import Vue from 'vue'
import { mapState } from 'vuex'
import { IOrg } from '@/type/orgs'
import { IStateOrg } from '@/store/modules/org'

export default Vue.extend({
  computed: {
    ...mapState<IStateOrg>('org', {
      org: (state: IStateOrg) => state.org.displayName,
      orgs: (state: IStateOrg) => state.orgs
    })
  },
  methods: {
    setOrg(org: IOrg) {
      this.$store.commit('thing/resetSerial')
      this.$store.commit('org/setOrg', org)
    }
  }
})
