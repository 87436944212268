import axios from 'axios'

const instance = axios.create({
  baseURL: `https://${process.env.VUE_APP_API_ENV}.aerial-tech.net/api/jiro/mgmt/v1`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

export default instance
