import {
  IStateDiagnostic,
  ICompositeDiagnosticResponse,
  ICompositeDiagnostic
} from '@/store/modules/diagnostic'

export default {
  setCompositeDiagnostic(state: IStateDiagnostic, { serial, response }: ICompositeDiagnosticResponse) {
    if (response === null || response.signal === null) return

    const { strength } = response.signal
    if (strength === null) return

    const { value } = strength
    if (value === null) return

    const { rangeAbsoluteMax, rangeAbsoluteMin, rangeAcceptableMax } = strength

    const _strength = {
      rangeAbsoluteMax: rangeAbsoluteMax,
      rangeAbsoluteMin: rangeAbsoluteMin,
      rangeAcceptableMax: rangeAcceptableMax,
      rangeAcceptableMin: -680,
      value: value
    }

    const compositeDiagnostic = {
      serial,
      strength: _strength
    } as ICompositeDiagnostic

    state.strength = [...state.strength, compositeDiagnostic]
  },

  resetCompositeDiagnostic(state: IStateDiagnostic) {
    state.strength = []
  }
}
