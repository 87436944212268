export enum EnvTarget {
  Develop = 'development',
  Production = 'production',
  Staging = 'staging',
  Test = 'test'
}

/** query example: {fromDate: '2019/01/01', toDate: '2020/01/01'}  */
export function getQuery(queryObj: Query): string {
  const query = Object.entries(queryObj)
    .map(pair => pair.map(encodeURIComponent).join('='))
    .join('&')

  return `?${query}`
}

interface Query {
  [key: string]: string
}
