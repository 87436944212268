import { AxiosResponse } from 'axios'
import { IStateNotification, INotification } from '@/store/modules/notification'

export const DEFAULT_ERROR_MESSAGE = 'Please contact Aerial support.'

export default {
  addNotification(state: IStateNotification, { config, status }: AxiosResponse) {
    const notification: INotification = {
      title: `${status} - ${config.url}`,
      content: DEFAULT_ERROR_MESSAGE
    }
    state.notifications.push(notification)
  },

  resetNotification(state: IStateNotification) {
    state.notifications = []
  }
}
