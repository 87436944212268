import 'normalize.css'
import Vue from 'vue'

import App from '@/App.vue'
import '@/registerServiceWorker'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vSelect from 'vue-select'
import vCalendar from 'v-calendar'
import Skeleton from '@/components/Skeleton.vue'

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)
Vue.component('Skeleton', Skeleton)

Vue.use(vCalendar)

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
