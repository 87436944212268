import { AxiosResponse } from 'axios'

import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'
import { APIRecentHistory } from '@/type/things/motion/recentHistory'

export default {
  async getMotionEvents({ commit, rootState }: any) {
    const { serial } = rootState.thing

    try {
      const response: AxiosResponse<APIRecentHistory> = await fetchApi.get(
        `/thing/${serial}/motion/recentHistory`
      )
      commit('history/setSuspectedMotionHistory', response.data.recentMotion, { root: true })
      commit('history/setHumanMotionHistory', response.data.recentMotion, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
