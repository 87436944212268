import moment from 'moment'

import { IStateHistory, IMotionEventData } from '@/store/modules/history'
import { APIEventInRecentHistory } from '@/type/things/motion/recentHistory'

export default {
  setHumanMotionHistory(state: IStateHistory, history: APIEventInRecentHistory[]) {
    if (history.length === 0) return

    const filteredEvents: APIEventInRecentHistory[] = []
    const result: IMotionEventData[] = []

    history.forEach((event, index) => {
      if (index > 0 && event.humanMotionDetected !== history[index - 1].humanMotionDetected) {
        filteredEvents.push(event)
      }
    })

    const trueEventsList = history.filter(event => event.humanMotionDetected === true)
    const lastTrueTsMs = trueEventsList[trueEventsList.length - 1].tsMs

    filteredEvents.forEach((event, index) => {
      if (event.humanMotionDetected === false && index > 0) {
        let secs = Math.round((event.tsMs - filteredEvents[index - 1].tsMs) / 1000)
        if (secs === 0) secs = 1
        const toPush: IMotionEventData = {
          startTsMs: filteredEvents[index - 1].tsMs,
          startTime: moment(filteredEvents[index - 1].tsMs).format('HH:mm:ss'),
          stopTsMs: event.tsMs,
          duration: secs <= 59 ? `${secs}sec` : `${Math.floor(secs / 60)}min ${secs % 60}sec`
        }

        result.push(toPush)
      } else if (event.humanMotionDetected === true && index === filteredEvents.length - 1) {
        let secs = Math.round((lastTrueTsMs - event.tsMs) / 1000)
        if (secs === 0) secs = 1
        const toPushLast: IMotionEventData = {
          ongoing: true,
          startTsMs: event.tsMs,
          startTime: moment(event.tsMs).format('HH:mm:ss'),
          stopTsMs: lastTrueTsMs,
          duration: secs <= 59 ? `${secs}sec` : `${Math.floor(secs / 60)}min ${secs % 60}sec`
        }

        result.push(toPushLast)
      }
    })

    state.humanMotionHistory = result.reverse()
  },

  setSuspectedMotionHistory(state: IStateHistory, history: APIEventInRecentHistory[]) {
    if (history.length === 0) return

    const result: IMotionEventData[] = []
    const filteredEvents: APIEventInRecentHistory[] = []

    history.forEach((event, index) => {
      if (index > 0 && event.motionDetected !== history[index - 1].motionDetected) {
        filteredEvents.push(event)
      }
    })

    const trueEventsList = history.filter(event => event.motionDetected === true)
    const lastTrueTsMs = trueEventsList[trueEventsList.length - 1].tsMs

    filteredEvents.forEach((event, index) => {
      if (event.motionDetected === false && index > 0) {
        let secs = Math.round((event.tsMs - filteredEvents[index - 1].tsMs) / 1000)
        if (secs === 0) secs = 1

        const toPush: IMotionEventData = {
          startTsMs: filteredEvents[index - 1].tsMs,
          startTime: moment(filteredEvents[index - 1].tsMs).format('HH:mm:ss'),
          stopTsMs: event.tsMs,
          duration: secs <= 59 ? `${secs}sec` : `${Math.floor(secs / 60)}min ${secs % 60}sec`
        }

        result.push(toPush)
      } else if (event.motionDetected === true && index === filteredEvents.length - 1) {
        let secs = Math.round((lastTrueTsMs - event.tsMs) / 1000)
        if (secs === 0) secs = 1
        const toPushLast: IMotionEventData = {
          ongoing: true,
          startTsMs: event.tsMs,
          startTime: moment(event.tsMs).format('HH:mm:ss'),
          stopTsMs: lastTrueTsMs,
          duration: secs <= 59 ? `${secs}sec` : `${Math.floor(secs / 60)}min ${secs % 60}sec`
        }

        result.push(toPushLast)
      }
    })

    state.suspectedMotionHistory = result.reverse()
  },

  resetMotionHistory(state: IStateHistory) {
    state.humanMotionHistory = []
    state.suspectedMotionHistory = []
  }
}
