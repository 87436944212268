import actions from '@/store/modules/org/actions'
import mutations from '@/store/modules/org/mutations'
import { IOrg } from '@/type/orgs'

export const state = {
  org: {
    id: '',
    displayName: ''
  },
  orgs: []
}

export const org = {
  namespaced: true,
  state,
  mutations,
  actions
}

export interface IStateOrg {
  org: IOrg
  orgs: IOrg[]
}
