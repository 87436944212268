import actions from '@/store/modules/diagnostic/actions'
import getters from '@/store/modules/diagnostic/getters'
import mutations from '@/store/modules/diagnostic/mutations'

export enum DiagnosticEnum {
  TooClose = 'tooClose',
  TooFar = 'tooFar'
}

export const state: IStateDiagnostic = {
  strength: []
}

export const diagnostic = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

// Types
export interface IStateDiagnostic {
  strength: ICompositeDiagnostic[]
}

export interface ICompositeDiagnostic {
  serial: string
  strength: {
    rangeAbsoluteMax: number
    rangeAbsoluteMin: number
    rangeAcceptableMax: number
    rangeAcceptableMin: number
    value: number
  }
}

export interface ICompositeDiagnosticResponse {
  serial: string
  response: {
    signal: {
      strength: {
        rangeAbsoluteMax: number
        rangeAbsoluteMin: number
        rangeAcceptableMax: number
        rangeAcceptableMin: number
        value: number
      }
    }
  }
}
