var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("Select.Lang.Title")))]),
      _c("v-select", {
        attrs: {
          label: "displayName",
          options: _vm.langs,
          placeholder: _vm.$t("Select.Lang.Placeholder"),
          clearable: false,
          value: _vm.$i18n.locale
        },
        on: { input: _vm.setLang }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }