import actions from '@/store/modules/composite/actions'
import getters from '@/store/modules/composite/getters'
import mutations from '@/store/modules/composite/mutations'

export enum AgentModeEnum {
  Ap = 'ap',
  Sta = 'sta'
}

export const state: IStateComposite = {
  agentMode: AgentModeEnum,
  compositeThings: []
}

export const composite = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}

export interface ICompositeThing {
  alias: string
  agentWifiOpMode: string
  clientDesired: string | null
  clientReported: string
  connected: boolean
  opStatus: {
    message: string
    notif: string
    type: string
  }
  sensitivityDesired: number
  sensitivityReported: number
  serial: string
}

export interface IStateComposite {
  agentMode: typeof AgentModeEnum
  compositeThings: ICompositeThing[]
}
