import mutations from '@/store/modules/theme/mutations'

/** Theme: please keep consistence with @/styles/variable.scss */
export enum IColorsEnum {
  Black = '#181818',
  Blue = '#7cb5ec',
  Red = '#bf2027',
  White = '#fefefe'
}

export const state: IStateTheme = {
  dark: true,
  mobile: false
}

export const theme = {
  namespaced: true,
  state,
  mutations
}

// Types
export interface IStateTheme {
  dark: boolean
  mobile: boolean
}
