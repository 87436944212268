import { IStateThings } from '@/store/modules/things'
import { APIThingInThings } from '@/type/things/things'

export default {
  setThings(state: IStateThings, things: APIThingInThings[]) {
    const serialCompositeRegex = new RegExp(/-c[0-2]/)
    const filteredThings = things
      .filter(({ serial }) => serial.match(serialCompositeRegex))
      .map(({ serial }) => serial.replace(serialCompositeRegex, ''))
    const options = Array.from(new Set(filteredThings)).map(serial => serial)

    state.things = options
  },

  resetThings(state: IStateThings) {
    state.things = []
  }
}
