import { AxiosResponse } from 'axios'

import { APIConnectedClients } from '@/type/things/connectedClients'
import { APIWifiClients } from '@/type/things/wifiClients'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getCompositeConnectedClients({ commit }: any, { serial }: any) {
    try {
      const response: AxiosResponse<APIConnectedClients> = await fetchApi.get(
        `/thing/${serial}/connectedClients`
      )
      commit('client/setCompositeConnectedClients', { response: response.data, serial }, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  },

  async putSelectedClient({ commit }: any, { serial, client }: any) {
    try {
      const response: AxiosResponse<APIWifiClients> = await fetchApi.put(`/thing/${serial}/wifiClients`, {
        wifiClientsMac: [client]
      })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
