import { IStateActivity } from '@/store/modules/activity'
import { APIMotionStatus } from '@/type/things/motion/status'

export default {
  setMotionStatus(state: IStateActivity, motionsStatus: APIMotionStatus) {
    state.motionStatus = motionsStatus
  },

  resetMotionStatus(state: IStateActivity) {
    state.motionStatus = null
  }
}
