import mutations from '@/store/modules/user/mutations'

export const state: IStateUser = {
  authenticated: false,
  user: null
}

export const user = {
  namespaced: true,
  state,
  mutations
}

// Types
export interface IStateUser {
  authenticated: boolean
  user: IUser | null
}

export interface IErrorUser {
  code: number
  message: string
  url: string
}

export interface IUser {
  email: string
  /** Expiration, as an epoch timestamp in seconds */
  name?: string
  nickname?: string
  exp?: number
}
