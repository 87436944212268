











import Vue from 'vue'
import { redirectAuth0 } from '@/utils/auth'

export enum ErrorEnum {
  Logout = 'logout',
  GoHome = 'goHome'
}

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      required: true
    },
    buttonMethod: {
      type: String,
      required: true
    }
  },
  methods: {
    btnAction() {
      if (this.buttonMethod === ErrorEnum.Logout) {
        return redirectAuth0('logout')
      }

      if (this.buttonMethod === ErrorEnum.GoHome) {
        return this.$router.push({ name: 'live' })
      }

      return redirectAuth0('logout')
    }
  }
})
