import { AxiosResponse } from 'axios'

import { APIThings } from '@/type/things/things'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getThings({ commit }: any) {
    try {
      const response: AxiosResponse<APIThings> = await fetchApi.get(`/things`)
      commit('things/setThings', response.data.things, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
