import Vue from 'vue'
import Vuex from 'vuex'

import { activity } from '@/store/modules/activity'
import { app } from '@/store/modules/app'
import { client } from '@/store/modules/client'
import { composite } from '@/store/modules/composite'
import { diagnostic } from '@/store/modules/diagnostic'
import { drawer } from '@/store/modules/drawer'
import { history } from '@/store/modules/history'
import { notification } from '@/store/modules/notification'
import { org } from '@/store/modules/org'
import { theme } from '@/store/modules/theme'
import { thing } from '@/store/modules/thing'
import { things } from '@/store/modules/things'
import { user } from '@/store/modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    activity,
    app,
    client,
    composite,
    diagnostic,
    drawer,
    history,
    notification,
    org,
    theme,
    thing,
    things,
    user
  }
})
