import { IStateTheme } from '@/store/modules/theme'
import { MOBILE_WIDTH } from '@/utils/constant'

export default {
  setMobile(state: IStateTheme) {
    state.mobile = !window || window.innerWidth < MOBILE_WIDTH
  },

  switchTheme(state: IStateTheme) {
    state.dark = !state.dark
  }
}
